import React from 'react';
import CTA from '../../components/CTA';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Skal vi tage en snak?" />

      <div className="bg-light-blue-50 w-full py-28 pt-36">
        <div className="container">
          <h1 className="heading-sm text-center">Tusind tak for din besked!</h1>
          <p className="sub-sm mx-auto max-w-2xl text-center mt-8">
            Vi vender tilbage lige så hurtigt som vi kan. Snup en kop kaffe i
            mellemtiden - det har du fortjent.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
